<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="celebrity_products"
          :items="celebrity_products"
          :itemHeader="celebrity_favourityItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      celebrity_products: [],
      celebrity_favourityItemHeader: [
        { text: "Celebrity", value: "celebrity_id.first_name" },
        { text: "Product", value: "product_id.arabic_title" },
        { text: "sequence", value: "sequence" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/celebrity_products?fields=id,sequence,product_id.arabic_title,product_id.id,celebrity_id.first_name,celebrity_id.id"
        );
        this.celebrity_products = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
